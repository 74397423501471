//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    objectPools: [],
});

const objectPoolSlice = createSlice({
    name:     'objectPool',
    initialState,
    reducers: {
        fetchObjectPools:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.objectPools')),
        fetchObjectPoolsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchObjectPoolsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                objectPools: {
                    $set: _.get(action, 'payload.objectPools', []),
                },
            });
        }),
        reset:                     () => {
            return {
                ...initialState,
            };
        },
    },
});

export const ObjectPoolActions = objectPoolSlice.actions;

export const ObjectPoolReducer = objectPoolSlice.reducer;

export const useObjectPool = (dispatch) => bindActionCreators(ObjectPoolActions, dispatch);

export default objectPoolSlice;
