//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import ObjectFilter                  from '@constants/ObjectFilter';
import useContextTranslator          from '@hooks/ContextTranslator';
import { useObject }                 from '@slices/object';
import Button                        from '@stateless/atomic/Button';
import ButtonColor                   from '@stateless/atomic/Button/ButtonColor';
import ButtonType                    from '@stateless/atomic/Button/ButtonType';
import { selectObjectFilter }        from '@store/selectors/object';
import { selectAssignedObjectPools } from '@store/selectors/objectPool';

import styles from './styles.module.scss';

const ObjectFilterRow = () => {
    const translator                     = useContextTranslator('components.objectFilter');
    const dispatch                       = useDispatch();
    const objectActions                  = useObject(dispatch);
    const assignedObjectPools            = useSelector(selectAssignedObjectPools);
    const selectedObjectPoolFilter       = useSelector(selectObjectFilter(ObjectFilter.ObjectPool));
    const selectedExistsObjectPoolFilter = useSelector(selectObjectFilter(ObjectFilter.ExistsObjectPool));
    const onObjectFilterClicked          = (objectPool) => () => {
        objectActions.removeObjectFilter({
            filterKey: ObjectFilter.ExistsObjectPool,
        });
        objectActions.addObjectFilter({
            filterKey:   ObjectFilter.ObjectPool,
            filterValue: objectPool.iri,
        });
    };
    const onShowAllObjectsClicked        = () => {
        objectActions.removeAllObjectFilters();
    };
    const onShowOwnObjectsClicked        = () => {
        objectActions.removeObjectFilter({
            filterKey: ObjectFilter.ObjectPool,
        });
        objectActions.addObjectFilter({
            filterKey:   ObjectFilter.ExistsObjectPool,
            filterValue: false,
        });
    };

    if (_.isEmpty(assignedObjectPools)) {
        return null;
    }

    const allSelected            = (
        _.isUndefined(selectedObjectPoolFilter) &&
        _.isUndefined(selectedExistsObjectPoolFilter)
    );
    const allButtonColor         = allSelected
        ? ButtonColor.default
        : ButtonColor.white;
    const ownSelected            = selectedExistsObjectPoolFilter === false;
    const ownButtonColor         = ownSelected
        ? ButtonColor.default
        : ButtonColor.white;
    const renderObjectPoolFilter = (objectPool) => {
        const isSelected  = objectPool.iri === selectedObjectPoolFilter;
        const buttonColor = isSelected
            ? ButtonColor.default
            : ButtonColor.white;

        return (
            <Button
                key={objectPool.iri}
                color={buttonColor}
                type={ButtonType.edgy}
                onClick={onObjectFilterClicked(objectPool)}
                text={objectPool.name}
            />
        );
    };

    return (
        <div className={styles.objectPoolFilterRow}>
            <Button
                color={allButtonColor}
                type={ButtonType.edgy}
                text={translator.t('allObjects')}
                onClick={onShowAllObjectsClicked}
            />
            <Button
                color={ownButtonColor}
                type={ButtonType.edgy}
                text={translator.t('ownObjects')}
                onClick={onShowOwnObjectsClicked}
            />
            {_.map(assignedObjectPools, renderObjectPoolFilter)}
        </div>
    );
};

export default ObjectFilterRow;
