//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ApiUrls from '@constants/ApiUrls';

const objectApi = (api) => {
    return {
        saveObject:   (object) => {
            const iri = _.get(object, 'iri');

            if (iri) {
                return api.put(
                    iri,
                    object,
                );
            }

            return api.post(ApiUrls.API_PROPERTY_URL, object);
        },
        fetchObjects: (parameters) => {
            return api.get(
                ApiUrls.API_PROPERTY_URL,
                {
                    ...parameters,
                    page: parameters.page || 1,
                },
            );
        },
        deleteObject: (iri) => {
            return api.delete(iri);
        },
    };
};

export default (api) => objectApi(api);
