//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

export const selectObjectPools = (state) => {
    return _.get(state, ['objectPool', 'objectPools'], []);
};

export const selectAssignedObjectPools = (state) => {
    return _.filter(
        selectObjectPools(state),
        (objectPool) => {
            return objectPool.userHasAssignment;
        },
    );
};

export default selectObjectPools;
