//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

export const selectEditObject = (state) => {
    return _.get(state, ['object', 'edit']);
};

export const selectObjects = (state) => {
    return _.get(state, ['object', 'objects']);
};

export const selectObjectPaging = (state) => {
    return _.get(state, ['object', 'paging']);
};

export const selectObjectPagingField = (field) => (state) => {
    return _.get(state, ['object', 'paging', field]);
};

export const selectObjectFilter  = (objectFilter) => (state) => {
    return _.get(state, ['object', 'filters', objectFilter], undefined);
};
export const selectObjectFilters = (state) => {
    return _.get(state, ['object', 'filters'], {});
};

export default selectEditObject;
