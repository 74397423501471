//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _             from 'lodash';
import ReactPaginate from 'react-paginate';
import styled        from 'styled-components';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconType  from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    onPageChange:       PropTypes.func,
    pageCount:          PropTypes.number,
    pageRangeDisplayed: PropTypes.number,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const PaginationContainer = styled.div`
    ul
    {
        .selected
        {
            background-color: ${(props) => props.theme.secondaryColor};
            color:            ${(props) => props.theme.buttonTextColor};
        }
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const PaginationWrapper = ({
    onPageChange       = _.noop,
    pageRangeDisplayed = 0,
    pageCount          = 0,

}) => {
    if (pageCount <= 1) {
        return null;
    }

    const renderNavigationLabel = (iconType) => {
        return (
            <Icon
                iconType={iconType}
            />
        );
    };

    return (
        <PaginationContainer>
            <ReactPaginate
                className={styles.paginationContainer}
                breakLabel={'...'}
                nextLabel={renderNavigationLabel(IconType.circleArrowRight)}
                onPageChange={onPageChange}
                pageRangeDisplayed={pageRangeDisplayed}
                pageCount={pageCount}
                previousLabel={renderNavigationLabel(IconType.circleArrowLeft)}
                renderOnZeroPageCount={null}
            />
        </PaginationContainer>
    );
};

PaginationWrapper.propTypes = propTypes;

export default PaginationWrapper;
