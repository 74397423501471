//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

/* eslint-disable react/display-name */
/* This middleware will be recognized as a react component. Eslint throws an error that it needs a display name which cannot be set. */

import _ from 'lodash';

import { NewsActions }         from '@slices/news';
import { ObjectPoolActions }   from '@slices/objectPool';
import { SmartUpdaterActions } from '@slices/smartUpdater';

const lifetime = Object.freeze({
    oneDay:      86400,
    oneWeek:     86400 * 7,
    quarterHour: 60 * 15,
    oneHour:     3600,
    twoHours:    3600 * 2,
    sixHours:    3600 * 6,
});

// The key has to be the same as in the reducer list
const timeoutConfigurations = Object.freeze({
    news:       {
        dataPropertyInReducer: 'news',
        fetchAction:           NewsActions.fetchNews,
        fetchActionType:       NewsActions.fetchNews().type,
        lifetimeInSeconds:     lifetime.oneDay,
        successActionType:     NewsActions.fetchNewsSucceeded().type,
    },
    objectPool: {
        dataPropertyInReducer: 'objectPools',
        fetchAction:           ObjectPoolActions.fetchObjectPools,
        fetchActionType:       ObjectPoolActions.fetchObjectPools().type,
        lifetimeInSeconds:     lifetime.oneDay,
        successActionType:     ObjectPoolActions.fetchObjectPoolsSucceeded().type,
    },
});

function smartUpdaterMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        let skipAction = false;
        const force    = _.get(action, 'payload.force', false);

        if (!force) {
            for (const timeoutConfigurationKey in timeoutConfigurations) {
                const timeoutConfiguration = timeoutConfigurations[timeoutConfigurationKey];
                const reducerName          = (
                    timeoutConfiguration.reducerName ||
                    timeoutConfigurationKey
                );

                switch (action.type) {
                    /* eslint-disable no-case-declarations */
                    case timeoutConfiguration.fetchActionType:
                        const state       = getState();
                        const dataReducer = state[reducerName];

                        if (!dataReducer) {
                            console.error('Reducer with key not found: ', reducerName);
                        }

                        const data = _.get(dataReducer, timeoutConfiguration.dataPropertyInReducer);

                        if (!_.isEmpty(data)) {
                            const smartUpdateReducer = state.smartUpdater;
                            const lastUpdateDate     = _.get(
                                smartUpdateReducer,
                                [
                                    'lastUpdateDates',
                                    timeoutConfigurationKey,
                                ],
                                false,
                            );

                            if (lastUpdateDate) {
                                const now                    = new Date().getTime();
                                const lifetimeInMilliseconds = timeoutConfiguration.lifetimeInSeconds * 1000;
                                const nextUpdateDate         = lastUpdateDate + lifetimeInMilliseconds;

                                if (now < nextUpdateDate) {
                                    skipAction = true;
                                }
                            }
                        }

                        break;

                    case timeoutConfiguration.successActionType:
                        const date = new Date().getTime();

                        next(SmartUpdaterActions.setLastFetchDate({
                            key: timeoutConfigurationKey,
                            date,
                        }));

                        break;
                    /* eslint-enable no-case-declarations */
                }

                if (skipAction) {
                    break;
                }
            }
        }

        if (!skipAction) {
            return next(action);
        }

        console.info('smartUpdaterMiddleware: skipping action: ', action);

        return null;
    };
}

export const configurations = timeoutConfigurations;

export default smartUpdaterMiddleware;
