//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _        from 'lodash';
import { put }  from 'redux-saga/effects';
import { take } from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import Routes                        from '@constants/Routes';
import SagaStateHelper               from '@helper/SagaStateHelper';
import { CustomerActions }           from '@slices/customer';
import { NewsActions }               from '@slices/news';
import { ObjectActions }             from '@slices/object';
import { ObjectPoolActions }         from '@slices/objectPool';
import { SubscriptionOptionActions } from '@slices/subscriptionOption';
import { UserActions }               from '@slices/user';
import { WidgetActions }             from '@slices/widget';
import selectPathname                from '@store/selectors/router';
import selectToken                   from '@store/selectors/user';

function* preLoadData() {
    const token    = yield SagaStateHelper.selector(selectToken);
    const pathName = yield SagaStateHelper.select(selectPathname);

    if (token) {
        yield put(ObjectActions.fetchObjects());
        yield put(CustomerActions.fetchCustomers());
        yield put(SubscriptionOptionActions.fetchSubscriptionOptions());
        yield put(UserActions.fetchUser());
        yield put(NewsActions.fetchNews());
        yield put(ObjectPoolActions.fetchObjectPools());
    }

    if (_.eq(pathName, Routes.widget)) {
        yield put(WidgetActions.fetchUnits());
    }
}

function* rehydrate() {
    yield put(UserActions.tryRestoreToken());
    yield take([
        UserActions.tryRestoreTokenCompleted().type,
    ]);

    yield call(preLoadData);
}

export default {
    rehydrate,
    preLoadData,
};
