//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }       from 'connected-react-router';
import I18n           from 'i18next';
import _              from 'lodash';
import { put }        from 'redux-saga/effects';
import { delay }      from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { takeEvery }  from 'redux-saga/effects';

import * as Api                from '@api/index';
import Routes                  from '@constants/Routes';
import UserFields              from '@constants/UserFields';
import ValidationError         from '@constants/ValidationError';
import DateTime                from '@helper/DateTime';
import Hydra                   from '@helper/Hydra';
import ImageHelper             from '@helper/ImageHelper';
import Notification            from '@helper/Notification';
import SagaStateHelper         from '@helper/SagaStateHelper';
import Token                   from '@helper/Token';
import { AlertBoxActions }     from '@slices/alertBox';
import { CalculationActions }  from '@slices/calculation';
import { CustomerActions }     from '@slices/customer';
import { DashboardActions }    from '@slices/dashboard';
import { LoadingActions }      from '@slices/loading';
import { NavigationActions }   from '@slices/navigation';
import { ObjectActions }       from '@slices/object';
import { PreloadActions }      from '@slices/preload';
import { SmartUpdaterActions } from '@slices/smartUpdater';
import { UnitActions }         from '@slices/unit';
import { UserActions }         from '@slices/user';
import { WidgetActions }       from '@slices/widget';
import NumberMapper            from '@store/helper/NumberMapper';
import { selectColorSettings } from '@store/selectors/user';
import { selectUserIri }       from '@store/selectors/user';
import { selectUser }          from '@store/selectors/user';

const afterLoginRoute = Routes.dashboard;

/**
 * All routes that are defined in this array are reachable without the
 * requirement of a active/valid session. If no session is present on
 * a route that is not in this array, the user is automatically redirected
 * to Routes.login (in the restoreToken-saga).
 *
 * @type {(string)[]}
 */
const routesWithoutSession = [
    Routes.login,
    Routes.widget,
    Routes.orderResponse,
    Routes.paymentFailed,
    Routes.signup,
    Routes.passwordReset,
    Routes.lostPassword,
];

function* requestLogin(email, password) {
    yield put(AlertBoxActions.clearAlerts());

    const response = yield call(
        Api.context.user.login,
        email,
        password,
    );

    if (response.ok) {
        const { token } = response.data;

        Api.context.user.setToken(token);

        yield put(UserActions.loginSucceeded({
            ...response.data,
        }));
    } else {
        yield put(UserActions.loginFailed({
            error: _.get(response, 'data.error'),
        }));
    }
}

function* login(action) {
    const { username, password } = action.payload;
    const user                   = yield SagaStateHelper.selectFromState('user');
    const usernameToUse          = username || user.username;
    const passwordToUse          = password || user.password;

    yield requestLogin(usernameToUse, passwordToUse);
}

function* loginWithCredentials(action) {
    const { username, password } = action.payload;

    yield put(UserActions.login({
        username,
        password,
    }));
}

function* loginFailed(action) {
    const error           = _.get(action, 'payload.error');
    const errorMessageKey = (
        error === ValidationError.userDisabled ?
            'notifications.userDisabled' :
            'loginError'
    );

    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(errorMessageKey),
    }));
}

function* logout() {
    yield delay(50);
    Api.context.user.setToken(null);
    yield put(ObjectActions.reset());
    yield put(UnitActions.reset());
    yield put(CustomerActions.reset());
    yield put(DashboardActions.reset());
    yield put(CalculationActions.logout());
    yield put(LoadingActions.resetOverlay());
    yield put(SmartUpdaterActions.clearAllLastFetchDate());
    yield put(NavigationActions.redirect({
        route: Routes.login,
    }));
}

function* logoutAfterTokenExpiration() {
    const userToken               = yield SagaStateHelper.selectFromState('user', 'token');
    const timeTillExpiration      = Token.getTimeTillExpiration(userToken);
    const daysToWaitForExpiration = 7;
    const sevenDaysInMilliseconds = DateTime.getDaysInMilliseconds(daysToWaitForExpiration);

    if (timeTillExpiration < sevenDaysInMilliseconds) {
        console.log('User: waiting for the token to expire up to:', `${daysToWaitForExpiration} days`);

        yield delay(timeTillExpiration);
        yield put(UserActions.logout());
    } else {
        console.log('User: skip waiting for the token to expire because it is more than:', `${daysToWaitForExpiration} days`);
    }
}

function* loginSucceeded() {
    // Todo: Redirect to the route the user should see after the successful login
    yield put(NavigationActions.redirect({
        route: afterLoginRoute,
    }));
    yield put(PreloadActions.preLoadData());
    yield* logoutAfterTokenExpiration();
}

function* restoreToken() {
    const pathname        = SagaStateHelper.selectFromState(
        'router',
        'location',
        'pathname',
    );
    const browserPathname = window.location.pathname;
    const user            = yield SagaStateHelper.selectFromState('user');

    if (user.token) {
        if (Token.isValidJWTToken(user.token)) {
            Api.context.user.setToken(user.token);

            if (
                pathname === Routes.login ||
                browserPathname === Routes.login
            ) {
                yield put(NavigationActions.redirect({
                    route: afterLoginRoute,
                }));
            }

            yield put(UserActions.tryRestoreTokenCompleted());
            yield* logoutAfterTokenExpiration();
            // TODO: You may want to fetch some data here
        } else {
            yield put(UserActions.logout());
        }
    } else if (
        routesWithoutSession.indexOf(pathname) === -1 &&
        routesWithoutSession.indexOf(browserPathname) === -1
    ) {
        // This delay is important otherwise the redirect will not work properly
        yield delay(50);
        yield put(NavigationActions.redirect({
            route: Routes.login,
        }));
    }

    yield put(UserActions.tryRestoreTokenCompleted());
}

function* saveColorSettings() {
    const colorSettings = yield SagaStateHelper.selector(selectColorSettings);
    const response      = yield call(
        Api.context.colorSettings.saveColorSettings,
        colorSettings,
    );
    const isNewPayload  = {
        isNew: !_.get(colorSettings, 'iri'),
    };

    if (response.ok) {
        yield put(UserActions.saveColorSettingsSucceeded({
            ...isNewPayload,
            colorSettings: _.get(response, 'data'),
        }));
    } else {
        yield put(UserActions.saveColorSettingsFailed(isNewPayload));
    }
}

function* saveUser() {
    const userIri  = yield SagaStateHelper.selector(selectUserIri);
    const user     = yield SagaStateHelper.selector(selectUser);
    const response = yield call(
        Api.context.user.saveUser,
        userIri,
        yield ImageHelper.mapImageObjectForApiSave(user),
    );

    if (response.ok) {
        yield put(UserActions.saveUserSucceeded({
            user: _.get(response, 'data'),
        }));
    } else {
        yield put(UserActions.saveUserFailed());
    }
}

function* signup(action) {
    const user     = _.get(action, 'payload.user');
    const response = yield call(
        Api.context.user.postUser,
        {
            ...user,
            'plainPassword': _.get(user, UserFields.password),
            'username':      _.get(user, UserFields.email),
        },
    );

    if (response.ok) {
        yield put(UserActions.signupSucceeded());
    } else {
        yield put(UserActions.signupFailed());
    }
}

function* signupFailed() {
    Notification.error('signupFailed');
}

function* signupSucceeded() {
    Notification.success('signupSucceeded');
    yield put(push(Routes.login));
}

function* passwordReset(action) {
    const email    = _.get(action, 'payload.email');
    const response = yield call(
        Api.context.user.passwordReset,
        email,
    );

    if (response.ok) {
        yield put(UserActions.passwordResetSucceeded());
    } else {
        yield put(UserActions.passwordResetFailed());
    }
}

function* passwordResetFailed() {
    Notification.error('passwordReset.error');
}

function* passwordResetSucceeded() {
    Notification.success('passwordReset.success');
}

function* setNewPassword(action) {
    const { email, newPassword, passwordToken } = action.payload;
    const response                              = yield call(
        Api.context.user.setNewPassword,
        {
            email,
            password: newPassword,
            token:    passwordToken,
        },
    );

    if (response.ok) {
        yield put(UserActions.setNewPasswordSucceeded());
    } else {
        yield put(UserActions.setNewPasswordFailed());
    }
}

function* setNewPasswordFailed() {
    Notification.error('setNewPassword.error');
}

function* setNewPasswordSucceeded() {
    Notification.success('setNewPassword.success');
    yield put(push(Routes.login));
}

function getTranslation(action, translationKey) {
    const prefix = (
        _.get(action, 'payload.isNew') ?
            'createColorSettings' :
            'updateColorSettings'
    );

    return `${prefix}.${translationKey}`;
}

function* saveColorSettingsFailed(action) {
    Notification.error(getTranslation(action, 'error'));
}

function* saveColorSettingsSucceeded(action) {
    Notification.success(getTranslation(action, 'success'));
}

function* saveUserFailed(action) {
    Notification.error('updateUser.error');
}

function* saveUserSucceeded(action) {
    Notification.success('updateUser.success');
}

function* fetchColorSettings() {
    const response = yield call(Api.context.colorSettings.fetchColorSettings);

    if (response.ok) {
        const colorSettingsList = Hydra.getMembersFromResponse(response.data);
        const colorSettings     = _.first(colorSettingsList);

        yield put(UserActions.fetchColorSettingsSucceeded({
            colorSettings,
        }));
        yield put(WidgetActions.setColorSettings({
            colorSettings,
        }));
    } else {
        yield put(UserActions.fetchColorSettingsFailed());
    }
}

function* fetchColorSettingsFailed() {
    Notification.error('fetchColorSettings.error');
}

function* fetchUser() {
    const response = yield call(
        Api.context.user.fetchUser,
        yield SagaStateHelper.selector(selectUserIri),
    );

    if (response.ok) {
        yield put(UserActions.fetchUserSucceeded({
            user: response.data,
        }));
    } else {
        yield put(UserActions.fetchUserFailed());
    }
}

function* fetchUserFailed() {
    Notification.error('fetchUser.error');
}

function* changePassword(action) {
    const response = yield call(
        Api.context.user.saveUser,
        yield SagaStateHelper.selector(selectUserIri),
        {
            plainPassword: _.get(action, 'payload.newPassword'),
        },
    );

    if (response.ok) {
        yield put(UserActions.changePasswordSucceeded());
    } else {
        yield put(UserActions.changePasswordFailed());
    }
}

function* changePasswordFailed() {
    Notification.error('changePassword.error');
}

function* changePasswordSucceeded() {
    Notification.success('changePassword.success');
    yield put(UserActions.logout());
}

function* saveSettings() {
    const user         = yield SagaStateHelper.selector(selectUser);
    const baseSettings = _.get(user, UserFields.baseSettings);
    const response     = yield call(
        Api.context.user.saveUser,
        yield SagaStateHelper.selector(selectUserIri),
        {
            baseSettings: {
                ...baseSettings,
                ...NumberMapper.mapFieldsToNumber(
                    baseSettings,
                    [
                        UserFields.annualTaxableIncome,
                        [UserFields.calculationFields, UserFields.interest],
                        [UserFields.calculationFields, UserFields.redemption],
                        [UserFields.calculationFields, UserFields.increaseInIncome],
                        [UserFields.calculationFields, UserFields.increaseInIncomeInterval],
                        [UserFields.calculationFields, UserFields.increaseInValue],
                        [UserFields.calculationFields, UserFields.increaseInValueInterval],
                        [UserFields.calculationFields, UserFields.increaseInOperatingCosts],
                        [UserFields.calculationFields, UserFields.increaseInOperatingCostsInterval],
                        [UserFields.calculationFields, UserFields.rentIncrease],
                        [UserFields.calculationFields, UserFields.rentIncreaseInterval],
                    ],
                ),
                'id': _.get(baseSettings, '@id'),
            },
        },
    );

    if (response.ok) {
        yield put(UserActions.saveSettingsSucceeded({
            user: response.data,
        }));
    } else {
        yield put(UserActions.saveSettingsFailed());
    }
}

function* saveSettingsFailed() {
    Notification.error('saveSettings.error');
}

function* saveSettingsSucceeded() {
    Notification.success('saveSettings.success');
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([UserActions.login().type],                       login),
        takeLatest([UserActions.loginWithCredentials().type],        loginWithCredentials),
        takeLatest([UserActions.loginFailed().type],                 loginFailed),
        takeEvery([UserActions.loginSucceeded().type],               loginSucceeded),
        takeLatest([UserActions.tryRestoreToken().type],             restoreToken),
        takeLatest([UserActions.logout().type],                      logout),
        takeLatest([NavigationActions.resetState().type],            logout),
        takeLatest([UserActions.saveColorSettings().type],           saveColorSettings),
        takeLatest([UserActions.saveColorSettingsFailed().type],     saveColorSettingsFailed),
        takeLatest([UserActions.saveColorSettingsSucceeded().type],  saveColorSettingsSucceeded),
        takeLatest([UserActions.fetchColorSettings().type],          fetchColorSettings),
        takeLatest([UserActions.fetchColorSettingsFailed().type],    fetchColorSettingsFailed),
        takeLatest([UserActions.fetchUser().type],                   fetchUser),
        takeLatest([UserActions.fetchUserFailed().type],             fetchUserFailed),
        takeLatest([UserActions.saveUser().type],                    saveUser),
        takeLatest([UserActions.saveUserFailed().type],              saveUserFailed),
        takeLatest([UserActions.saveUserSucceeded().type],           saveUserSucceeded),
        takeLatest([UserActions.changePassword().type],              changePassword),
        takeLatest([UserActions.changePasswordFailed().type],        changePasswordFailed),
        takeLatest([UserActions.changePasswordSucceeded().type],     changePasswordSucceeded),
        takeLatest([UserActions.saveSettings().type],                saveSettings),
        takeLatest([UserActions.saveSettingsFailed().type],          saveSettingsFailed),
        takeLatest([UserActions.saveSettingsSucceeded().type],       saveSettingsSucceeded),
        takeLatest([UserActions.signup().type],                      signup),
        takeLatest([UserActions.signupFailed().type],                signupFailed),
        takeLatest([UserActions.signupSucceeded().type],             signupSucceeded),
        takeLatest([UserActions.passwordReset().type],               passwordReset),
        takeLatest([UserActions.passwordResetFailed().type],         passwordResetFailed),
        takeLatest([UserActions.passwordResetSucceeded().type],      passwordResetSucceeded),
        takeLatest([UserActions.setNewPassword.type],                setNewPassword),
        takeLatest([UserActions.setNewPasswordFailed().type],        setNewPasswordFailed),
        takeLatest([UserActions.setNewPasswordSucceeded().type],     setNewPasswordSucceeded),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
