//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { takeLatest } from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';

import * as Api              from '@api/index';
import Hydra                 from '@helper/Hydra';
import Notification          from '@helper/Notification';
import { ObjectPoolActions } from '@slices/objectPool';

function* fetchObjectPools() {
    const response = yield call(Api.context.objectPool.fetchObjectPools);

    if (response.ok) {
        const objectPools = Hydra.getMembersFromResponse(response.data);

        yield put(ObjectPoolActions.fetchObjectPoolsSucceeded({
            objectPools,
        }));
    } else {
        yield put(ObjectPoolActions.fetchObjectPoolsFailed());
    }
}

function* fetchObjectPoolsFailed() {
    Notification.error('fetchObjectPools.error');
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([ObjectPoolActions.fetchObjectPools().type],       fetchObjectPools),
        takeLatest([ObjectPoolActions.fetchObjectPoolsFailed().type], fetchObjectPoolsFailed),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
