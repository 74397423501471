//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    currentPage:  'currentPage',
    firstPage:    'firstPage',
    itemsPerPage: 'itemsPerPage',
    lastPage:     'lastPage',
    nextPage:     'nextPage',
    page:         'page',
    previousPage: 'previousPage',
    totalItems:   'totalItems',
});

export const defaultPaging = {
    currentPage:  1,
    firstPage:    1,
    itemsPerPage: 10,
    lastPage:     1,
    nextPage:     null,
    page:         1,
    previousPage: null,
    totalItems:   0,
};
