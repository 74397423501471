//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _      from 'lodash';
import styled from 'styled-components';

import PropTypes    from '@components/PropTypes';
import { svgColor } from '@helper/StyledMixins';
import useAppTheme  from '@hooks/AppTheme';
import Icon         from '@stateless/atomic/Icon';
import IconType     from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    avatar:    PropTypes.string,
    menuItems: PropTypes.array,
    name:      PropTypes.string,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const UserWrapper = styled.div`
    color: ${(props) => props.theme.textColor} !important;
`;

const AvatarWrapper = styled.div`
    background-color: ${(props) => props.theme.secondaryColor} !important;
`;

const MenuItemWrapper = styled.div`
    background-color: ${(props) => props.theme.primaryColor} !important;
    color:            ${(props) => props.theme.textColor} !important;

    &:hover
    {
        background-color: ${(props) => props.theme.backgroundColor} !important;
        color:            ${(props) => props.theme.secondaryColor} !important;

        ${(props) => svgColor(props.theme.secondaryColor)}
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const ProfileMenu = ({
    avatar    = null,
    name      = '',
    menuItems = [],
}) => {
    const appTheme = useAppTheme();

    function renderMenuItems() {
        return _.map(menuItems, (item) => {
            const { onClick, text, iconType } = item;

            return (
                <MenuItemWrapper
                    key={text}
                    className={styles.menuItem}
                    onClick={onClick}
                >
                    <Icon
                        customColor={appTheme.textColor}
                        iconType={iconType}
                    />
                    {text}
                </MenuItemWrapper>
            );
        });
    }

    return (
        <div className={styles.profileMenu}>
            <UserWrapper
                className={styles.userWrapper}
            >
                {name}
                <Icon
                    customColor={appTheme.textColor}
                    iconType={IconType.chevronDown}
                />
            </UserWrapper>
            <AvatarWrapper
                className={styles.avatarWrapper}
            >
                <img
                    src={avatar}
                    alt={'avatar'}
                />
            </AvatarWrapper>
            <div className={styles.menuWrapper}>
                {renderMenuItems()}
            </div>
        </div>
    );
};

ProfileMenu.propTypes = propTypes;

export default ProfileMenu;
