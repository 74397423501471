//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import { defaultPaging }  from '@constants/PagingFields';
import PagingFields       from '@constants/PagingFields';
import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';
import Merge              from '@store/helper/Merge';

const initialState = Object.freeze({
    edit:    {
        description:               null,
        address:                   {
            street:      null,
            houseNumber: null,
            addition:    null,
            postalCode:  null,
            city:        null,
            country:     null,
        },
        additionalPurchasingCosts: {
            realEstateTransferTax: 0,
            notaryFees:            0,
            mediationCosts:        0,
            unitCosts:             0,
        },
        ownerCosts:                {
            propertyManagerCosts: 0,
            unitManagerCosts:     0,
            reserves:             0,
        },
        keyTaxData:                {
            landShare: 0,
        },
    },
    objects: [],
    paging:  defaultPaging,
    filters: {},
});

const objectSlice = createSlice({
    name:     'object',
    initialState,
    reducers: {
        createNewObject:        (state, action) => {
            return update(state, {
                edit: {
                    $set: initialState.edit,
                },
            });
        },
        editObject:             (state, action) => {
            return update(state, {
                edit: {
                    $set: _.get(action, 'payload.object', initialState.edit),
                },
            });
        },
        editObjectData:         (state, action) => {
            return update(state, {
                edit: {
                    $set: Merge.mergeWith(
                        _.get(state, 'edit', initialState.edit),
                        _.get(action, 'payload.edit', {}),
                    ),
                },
            });
        },
        saveObject:             LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.objectSave')),
        saveObjectFailed:       LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveObjectSucceeded:    LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                edit: {
                    $set: initialState.edit,
                },
            });
        }),
        fetchObjects:           LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.objects')),
        fetchObjectsFailed:     LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchObjectsSucceeded:  LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                objects: {
                    $set: _.get(action, 'payload.objects', []),
                },
                paging:  {
                    $set: _.get(action, 'payload.paging', 0),
                },
            });
        }),
        deleteObject:           LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.objectDelete')),
        deleteObjectFailed:     LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        deleteObjectSucceeded:  LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        reset:                  () => {
            return {
                ...initialState,
            };
        },
        setCurrentPage:         (state, action) => {
            return update(state, {
                paging: {
                    [PagingFields.currentPage]: {
                        $set: _.get(action, 'payload.page', 1),
                    },
                },
            });
        },
        addObjectFilter:        (state, action) => {
            const filterKey   = _.get(action, 'payload.filterKey', null);
            const filterValue = _.get(action, 'payload.filterValue', null);

            if (_.isNull(filterKey)) {
                return state;
            }

            const filters = _.get(state, 'filters', null);

            if (_.isNull(filters)) {
                return update(state, {
                    $set: {
                        ...state,
                        filters: {
                            [filterKey]: filterValue,
                        },
                    },
                });
            }

            return update(state, {
                filters: {
                    [filterKey]: {
                        $set: filterValue,
                    },
                },
            });
        },
        removeObjectFilter:     (state, action) => {
            const filterKey = _.get(action, 'payload.filterKey', null);

            if (_.isNull(filterKey)) {
                return state;
            }

            const filters = _.get(state, 'filters', null);

            if (_.isNull(filters)) {
                return state;
            }

            return update(state, {
                filters: {
                    $unset: [
                        filterKey,
                    ],
                },
            });
        },
        removeAllObjectFilters: (state) => {
            return update(state, {
                filters: {
                    $set: {},
                },
            });
        },
    },
});

export const ObjectActions = objectSlice.actions;

export const ObjectReducer = objectSlice.reducer;

export const useObject = (dispatch) => bindActionCreators(ObjectActions, dispatch);

export default objectSlice;
